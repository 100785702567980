import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useHistory, useParams } from 'react-router';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { PAYMENT_SPECIAL_INVOICE_PATH, pathToRoute } from '../constants/routes';
import { useUserAuthInfo } from '../components/auth';
import tableIcons from '../constants/tableIcons';
import ViewBillButton from './ViewBillButton';
import { prepareMaterialTableData, formatDateField } from '../utils/helpers';

const specialInvoiceMapping = {
  '1_SC_C': 'Contract Modification',
  '1_FS_C': 'Field Service Invoice',
  '1_HI_C': 'Home Improvement Invoice',
  '1_LP_C': 'Lease Prepay Invoice',
  '1_SP_C': 'System Purchase Invoice',
  UPFRONT: 'Prepay Invoice',
};

const SPECIAL_INVOICES_FRAGMENT = gql`
  fragment SpecialInvoiceFields on Prospect {
    id
    billing(contactId: $contactId) {
      id
      transactions {
        id
        date
        amount
        isSpecialInvoice
        invoiceType
        invoiceKey
      }
    }
  }
`;

const SpecialInvoices = ({ data, loading }) => {
  const { isUser } = useUserAuthInfo();
  const { prospectId, contactId } = useParams();
  const history = useHistory();
  const specialInvoices = prepareMaterialTableData(
    data?.prospect?.billing?.transactions?.filter((t) => t.isSpecialInvoice && t.status !== 'PAID'),
  );

  if (!specialInvoices || specialInvoices.length < 1) return null;

  const navigateToSpecialInvoicePayment = (amount, invoiceType) => {
    history.push(pathToRoute(PAYMENT_SPECIAL_INVOICE_PATH, { prospectId, contactId }), {
      specialInvoiceAmount: amount,
      referenceNumberPrefix: invoiceType,
    });
  };

  return (
    <Grid item xs={12}>
      <MaterialTable
        title="Misc. Sunrun Balance"
        columns={[
          {
            title: 'Date',
            field: 'date',
            type: 'date',
            defaultSort: 'desc',
            render: (invoice) => formatDateField(invoice?.date),
          },
          {
            title: 'Type',
            render: ({ invoiceType }) => {
              if (specialInvoiceMapping[invoiceType]) {
                return specialInvoiceMapping[invoiceType];
              }
              return 'Invoice';
            },
          },
          {
            title: 'Invoice',
            sorting: false,
            searchable: false,
            // eslint-disable-next-line react/prop-types
            render: ({ date, invoiceKey }) =>
              invoiceKey && <ViewBillButton small invoiceDate={date} invoiceKey={invoiceKey} contactId={contactId} />,
          },
          // adding this so that the columns line up with the transaction table below. When we actually get statuses for
          // these special invoices we should put it here
          {
            title: null,
          },
          {
            title: 'Amount',
            field: 'amount',
            type: 'currency',
            sorting: false,
            cellStyle: { textAlign: 'right' },
          },
          {
            title: null,
            cellStyle: { textAlign: 'right' },
            render: (specialInvoice) => {
              return isUser ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigateToSpecialInvoicePayment(specialInvoice.amount, specialInvoice.invoiceType)}
                >
                  Pay Now
                </Button>
              ) : null;
            },
          },
        ]}
        isloading={loading}
        data={specialInvoices}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          emptyRowsWhenPaging: false,
          paging: specialInvoices.length > 10,
          draggable: false,
          showEmptyDataSourceMessage: !loading,
        }}
        icons={tableIcons}
      />
    </Grid>
  );
};

SpecialInvoices.propTypes = {
  data: PropTypes.shape({
    prospect: PropTypes.shape({
      billing: PropTypes.shape({
        transactions: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            amount: PropTypes.number,
            isSpecialInvoice: PropTypes.bool,
            invoiceType: PropTypes.string,
          }),
        ),
      }),
    }),
  }),
  loading: PropTypes.bool.isRequired,
};

export { SPECIAL_INVOICES_FRAGMENT };
export default SpecialInvoices;
