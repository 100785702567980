import PropTypes from 'prop-types';
import React from 'react';
import { css } from '@emotion/core';
import { PaymentFormLabels, UIPaymentMethodEnum } from './paymentConstants';
import { Box, Typography } from '@material-ui/core';
import formatCurrency from '../../utils/format-currency';

/* TODO this still needs accessibility for screen readers */
const ReadOnlyFields = ({ selectedPaymentAmountOption, paymentAmount, selectedPaymentMethod }) => {
  return (
    <Box data-testid="post-initialize-readonly-fields">
      {selectedPaymentAmountOption && paymentAmount && (
        <Box data-testid="payment-amount-readonly" sx={{ marginBottom: 36 }}>
          <Typography
            variant="h5"
            component="span"
            css={css`
              color: #000;
            `}
          >
            {PaymentFormLabels.PAYMENT_AMOUNT}
          </Typography>
          <Box
            css={css`
              display: flex;
              justify-content: space-between;
              margin-top: 16px;
            `}
          >
            <Box>
              <Typography
                css={css`
                  margin-bottom: 8px;
                `}
              >
                {selectedPaymentAmountOption}
              </Typography>
              <Typography>{formatCurrency(paymentAmount)}</Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box data-testid="payment-method-readonly">
        <Typography
          variant="h5"
          component="span"
          css={css`
            color: #000;
          `}
        >
          {PaymentFormLabels.PAYMENT_METHOD}
        </Typography>
        <Box
          css={css`
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
          `}
        >
          <Typography>{UIPaymentMethodEnum[selectedPaymentMethod]}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

ReadOnlyFields.propTypes = {
  selectedPaymentAmountOption: PropTypes.string,
  paymentAmount: PropTypes.number,
  selectedPaymentMethod: PropTypes.string.isRequired,
};

export default ReadOnlyFields;
