import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { css } from '@emotion/core';
import {
  PaymentAmountRadioOptions,
  PaymentFormLabels,
  OtherPaymentAmountError,
  SpecialInvoiceTypeUILabel,
} from './paymentConstants';
import formatCurrency from '../../utils/format-currency';
import { handleOtherAmountOnChange } from './otherAmountHelpers';
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from '@material-ui/core';

/**
 * Handles Payment amount and method for regular one time payment and special invoice
 * Requires selections before allowing to proceed to initialize payments iframe
 *
 * @param totalAmountDue - can be total account balance or special invoice total
 * @param selectedPaymentAmountOption - total balance or custom amount
 * @param referenceNumberPrefix - specific types for special invoices
 * @param canOverPay - allowed on regular payments with a max in upcoming story TBD
 * @param handlePaymentAmountOptionsOnChange - parent handler
 * @param handleOtherPaymentAmountOnChange - parent handler
 * @param handlePaymentAmountValidation - parent handler
 *
 ** Note - Only Spotlight allows overpaying on regular payments, partial payments for special invoices
 */
const PaymentAmountOptions = ({
  totalAmountDue,
  selectedPaymentAmountOption,
  referenceNumberPrefix,
  canOverPay,
  handlePaymentAmountOptionsOnChange,
  handleOtherPaymentAmountOnChange,
  handlePaymentAmountValidation,
}) => {
  const otherAmountInputRef = useRef();
  const [otherAmount, setOtherAmount] = useState(0);
  const [otherAmountWithFormat, setOtherAmountWithFormat] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(' '); // space added to prevent bouncing
  const otherAmountMax = 100000; // placeholder amount / actual TBD

  useEffect(() => {
    handlePaymentAmountValidation(hasError);
  }, [hasError, handlePaymentAmountValidation]);

  const clearError = () => {
    setHasError(false);
    setErrorMessage(' ');
  };

  const handleRadioOnChange = (event) => {
    clearError();
    handlePaymentAmountOptionsOnChange(event.target.value);
  };

  const handleOtherAmountFocus = () => {
    // sets focus on text input when parent radio is selected
    otherAmountInputRef.current.focus();
  };

  const validateOtherAmountOnBlur = () => {
    if (selectedPaymentAmountOption === PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE) {
      return;
    }

    if (otherAmount < 1) {
      setHasError(true);
      setErrorMessage(OtherPaymentAmountError.MINIMUM_REQUIRED);
      return;
    }
    if (!canOverPay && otherAmount >= 1 && otherAmount > totalAmountDue) {
      setHasError(true);
      setErrorMessage(OtherPaymentAmountError.EXCEEDED_INVOICE_TOTAL);
    } else if (canOverPay && otherAmount >= 1 && otherAmount > otherAmountMax) {
      setHasError(true);
      setErrorMessage(OtherPaymentAmountError.MAXIMUM_EXCEEDED);
    } else {
      setHasError(false);
    }
  };

  const onChangeOtherAmount = (event) => {
    const { displayString, numberValue } = handleOtherAmountOnChange(event);

    if (displayString === '') {
      setOtherAmountWithFormat('');
    } else {
      setOtherAmountWithFormat(displayString);
    }

    setOtherAmount(numberValue);
    handleOtherPaymentAmountOnChange(numberValue);
  };

  return (
    <FormControl
      fullWidth={true}
      data-testid="payment-amount-container"
      css={css`
        margin-bottom: 16px;
        .MuiRadio-colorSecondary.Mui-checked {
          color: rgb(24, 30, 58);
      `}
    >
      <FormLabel id="payment-amount-label">
        <Typography
          variant="h5"
          component="span"
          css={css`
            color: #000;
          `}
        >
          {PaymentFormLabels.PAYMENT_AMOUNT}
        </Typography>
      </FormLabel>
      <RadioGroup
        data-testid="payment-amount-group"
        aria-labelledby="payment-amount-label"
        value={selectedPaymentAmountOption}
        name="payment-amount-group"
        onChange={handleRadioOnChange}
        row
      >
        <Box sx={{ display: 'flex', gap: '40px', marginTop: '16px' }}>
          <Box>
            <Box>
              {referenceNumberPrefix
                ? SpecialInvoiceTypeUILabel[referenceNumberPrefix]
                : PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE}
            </Box>
            <FormControlLabel
              data-testid="full-balance-label"
              value={PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE}
              control={<Radio />}
              label={formatCurrency(totalAmountDue)}
            />
          </Box>
          <Box>
            <Box>{PaymentAmountRadioOptions.OTHER_AMOUNT}</Box>
            <FormControlLabel
              data-testid="other-amount-label"
              value={PaymentAmountRadioOptions.OTHER_AMOUNT}
              control={<Radio onFocus={() => handleOtherAmountFocus()} />}
              label={
                <TextField
                  inputRef={otherAmountInputRef}
                  inputProps={{ 'data-testid': 'other-amount-input' }}
                  placeholder="$0.00"
                  value={otherAmountWithFormat}
                  onChange={onChangeOtherAmount}
                  onFocus={() => handlePaymentAmountOptionsOnChange(PaymentAmountRadioOptions.OTHER_AMOUNT)}
                  onBlur={validateOtherAmountOnBlur}
                  error={hasError}
                  helperText={hasError ? errorMessage : null}
                  css={css`
                    .MuiInputBase-input {
                      padding: 0;
                    }
                  `}
                />
              }
            />
          </Box>
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

PaymentAmountOptions.propTypes = {
  totalAmountDue: PropTypes.number.isRequired,
  selectedPaymentAmountOption: PropTypes.string.isRequired,
  referenceNumberPrefix: PropTypes.string,
  canOverPay: PropTypes.bool,
  handlePaymentAmountOptionsOnChange: PropTypes.func.isRequired,
  handleOtherPaymentAmountOnChange: PropTypes.func.isRequired,
  handlePaymentAmountValidation: PropTypes.func.isRequired,
};

export default PaymentAmountOptions;
