import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { sessionExpiredContent } from './paymentConstants';

const SessionExpiredModal = ({ isOpen, onClose, handleCTA }) => {
  const handleCTAClick = () => {
    handleCTA();
    onClose();
  };

  // more direction needed - noted in story 4077
  // options - redirect to billing, hide form, reload form
  // for now closing will reload form to prevent proceeding with expired token
  const closeModal = () => {
    handleCTA();
    onClose();
  };

  const onModalClosed = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault();
      return;
    }
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onModalClosed}
      aria-labelledby="form-dialog-title"
      data-testid="payment-session-expired-modal"
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">{sessionExpiredContent.TITLE}</Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            position: absolute;
            right: 8px;
            top: 8px;
            color: #9e9e9e;
          `}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          color="textPrimary"
          css={css`
            font-weight: 500;
          `}
        >
          {sessionExpiredContent.DESCRIPTION}
        </DialogContentText>
        <DialogContentText color="textPrimary">{sessionExpiredContent.CTA}</DialogContentText>
      </DialogContent>
      <DialogActions
        css={css`
          margin: 0;
          padding: 8px;
        `}
      >
        <Button autoFocus onClick={handleCTAClick} color="primary">
          {sessionExpiredContent.CTA_BUTTON}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SessionExpiredModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  handleCTA: PropTypes.func,
};

export default SessionExpiredModal;
