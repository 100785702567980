import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useHybridAuthToken } from '../../components/hybridAuthToken';
import {
  MessageType,
  PayMethodEnum,
  PaymentAmountRadioOptions,
  PaymentFormLabels,
  UIPaymentMethodLongEnum,
} from './paymentConstants';
import LinearProgress from '@material-ui/core/LinearProgress';
import PaymentMethodOptions from './PaymentMethodOptions';
import PaymentAmountOptions from './PaymentAmountOptions';
import ReadOnlyFields from './ReadOnlyFields';
import ExperiencePaymentsUI from './ExperiencePaymentsUI';
import { Button } from '@sunrun/component-library';
import { PaymentConfirmationModal } from './PaymentConfirmationModal';

// WIP V2 using experience-payments-ui
const OneTimePayment = ({ userInfo, specialInvoiceAmount, referenceNumberPrefix, isBillingEscalations }) => {
  // default is outstanding balance
  let { totalAmountDue } = userInfo;
  if (specialInvoiceAmount) {
    totalAmountDue = specialInvoiceAmount;
  }

  const hybridAuth = useHybridAuthToken();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [hasPaymentAmountError, setHasPaymentAmountError] = useState(false);
  const [selectedPaymentAmountOption, setSelectedPaymentAmountOption] = useState(
    Number(totalAmountDue) > 0
      ? PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE
      : PaymentAmountRadioOptions.OTHER_AMOUNT,
  );
  const [otherPaymentAmount, setOtherPaymentAmount] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PayMethodEnum.CHECKING);
  const [successResults, setSuccessResults] = useState();

  const onSuccess = (paymentSuccessPayload) => {
    setSuccessResults(paymentSuccessPayload);
    setShowPaymentForm(false);
  };

  const paymentAmount =
    selectedPaymentAmountOption === PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE
      ? totalAmountDue
      : otherPaymentAmount;

  const purchasedThru = userInfo?.purchasedThru || '';
  const isCostco = purchasedThru.toLowerCase().includes('costco');

  /**
   * We normally do not allow card payments for special invoices,
   * except if they are Costo customers or in billing escalations.
   */
  let allowCardPayment = true;
  if (specialInvoiceAmount) {
    allowCardPayment = isBillingEscalations || isCostco;
  }

  const initializeFormProps = useMemo(() => {
    if (!userInfo) {
      return;
    }

    const payload = {
      amount: paymentAmount,
      authToken: hybridAuth,
      autopayEnrollmentEnabled: false, // not for autopay
      isIframe: true,
      payMethod: selectedPaymentMethod,
      showCancelPaymentButton: false,
      repAssisted: true,
      referenceNumberPrefix: referenceNumberPrefix ?? null,
      userInfo,
    };

    return {
      payload,
      type: MessageType.INBOUND_INITIALIZE_PAYMENT,
      path: '/payment',
    };
  }, [paymentAmount, selectedPaymentMethod, userInfo, hybridAuth, referenceNumberPrefix]);

  const handlePaymentAmountOptionsOnChange = (value) => {
    setSelectedPaymentAmountOption(value);
  };

  const handleOtherPaymentAmountOnChange = (value) => {
    setOtherPaymentAmount(Number(value));
  };

  const handlePaymentAmountValidation = (hasError) => {
    if (hasError && !hasPaymentAmountError) {
      setHasPaymentAmountError(true);
    }

    if (!hasError && hasPaymentAmountError) {
      setHasPaymentAmountError(false);
    }
  };

  const handlePaymentMethodOnChange = (value) => {
    setSelectedPaymentMethod(value);
  };

  const handleContinueOnPress = () => {
    setShowPaymentForm(true);
  };

  const PreInitializedEditableFields = (
    <div data-testid="pre-initialize-editable-fields">
      <PaymentAmountOptions
        totalAmountDue={Number(totalAmountDue)}
        selectedPaymentAmountOption={selectedPaymentAmountOption}
        referenceNumberPrefix={referenceNumberPrefix}
        canOverPay={!referenceNumberPrefix}
        handlePaymentAmountOptionsOnChange={handlePaymentAmountOptionsOnChange}
        handleOtherPaymentAmountOnChange={handleOtherPaymentAmountOnChange}
        handlePaymentAmountValidation={handlePaymentAmountValidation}
      />
      <PaymentMethodOptions
        selectedPaymentMethod={selectedPaymentMethod}
        handlePaymentMethodOnChange={handlePaymentMethodOnChange}
        isCardEligible={allowCardPayment}
      />
      <Button
        primary={true}
        data-testid="proceed-to-enter-payment-details"
        disabled={hasPaymentAmountError || showPaymentForm}
        onClick={handleContinueOnPress}
      >
        {PaymentFormLabels.ENTER_PAYMENT_DETAILS}
      </Button>
    </div>
  );

  const PostInitializedReadOnlyFields = () => {
    return (
      <ReadOnlyFields
        selectedPaymentMethod={selectedPaymentMethod}
        selectedPaymentAmountOption={selectedPaymentAmountOption}
        paymentAmount={Number(paymentAmount)}
      />
    );
  };

  if (!hybridAuth) return <LinearProgress />;

  return (
    <div role="region" aria-live="assertive" data-testid="one-time-payment-container">
      {successResults && (
        <PaymentConfirmationModal
          paymentAmount={successResults.amount}
          lastFour={successResults.lastFour}
          paymentMethod={UIPaymentMethodLongEnum[selectedPaymentMethod]}
          confirmationNumber={successResults.confirmationNumber}
          email={successResults.email || userInfo.email}
        />
      )}
      {!successResults && !showPaymentForm && PreInitializedEditableFields}
      {!successResults && showPaymentForm && (
        <>
          <PostInitializedReadOnlyFields />
          <ExperiencePaymentsUI initializeFormProps={initializeFormProps} onSuccess={onSuccess} />
        </>
      )}
    </div>
  );
};

OneTimePayment.propTypes = {
  userInfo: PropTypes.shape({
    prospectId: PropTypes.string.isRequired,
    contactId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    mobilePhoneNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    lastDueDate: PropTypes.string,
    purchasedThru: PropTypes.string,
    totalAmountDue: PropTypes.string,
  }),
  specialInvoiceAmount: PropTypes.number,
  referenceNumberPrefix: PropTypes.string,
  isBillingEscalations: PropTypes.bool,
};

export default OneTimePayment;
