import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import useEnvConfig from '../../hooks/useEnvConfig';
import { MessageType, getExperiencePaymentsUIUrl } from './paymentConstants';
import { css } from '@emotion/core';
import SessionExpiredModal from './SessionExpiredModal';
import rollbar from '../../utils/rollbar';
import mixpanel from '../../utils/mixpanel';
import { sendMessage } from '../../utils/iframe-helpers';

const ExperiencePaymentsUI = ({ initializeFormProps, onSuccess }) => {
  const { paymentEnv } = useEnvConfig();
  const iFrameRef = useRef();
  const [iFrameHeight, setIFrameHeight] = useState(0);
  const [showExpiredErrorModal, setShowExpiredErrorModal] = useState(false);
  const [iframeKey, setIframeKey] = useState(1); // updates force reload from session expired modal
  const experiencePaymentsURL = getExperiencePaymentsUIUrl(paymentEnv);

  const sendDataToEmbeddedView = useCallback(
    (data) => {
      sendMessage(iFrameRef.current, experiencePaymentsURL, data);
    },
    [iFrameRef, experiencePaymentsURL],
  );

  // setup initializing form
  const initializeHtmlForm = useCallback(() => {
    sendDataToEmbeddedView({
      payload: initializeFormProps.payload,
      type: initializeFormProps.type,
    });
  }, [initializeFormProps, sendDataToEmbeddedView]);

  const messageHandler = useCallback(
    (message) => {
      const { payload, type } = message.data;

      switch (type) {
        case undefined:
          return;

        case MessageType.OUTBOUND_WINDOW_RESIZE:
          setIFrameHeight(payload.height);
          break;

        case MessageType.OUTBOUND_SESSION_EXPIRED:
          rollbar.warn('Experience Payments UI: Card payment session expired');
          setShowExpiredErrorModal(true);
          break;

        case MessageType.OUTBOUND_REVIEW_SCREEN_OPEN:
          mixpanel.track('Experience Payments UI: Review Payment information');
          break;

        case MessageType.OUTBOUND_ANALYTICS_EVENT: {
          mixpanel.track(`Experience Payments UI Analytics: ${type}`, payload);
          break;
        }
        case MessageType.OUTBOUND_PAYMENT_SUCCESS:
        case MessageType.OUTBOUND_AUTOPAY_SUCCESS:
          onSuccess(payload);
          break;

        case MessageType.OUTBOUND_LOG: {
          const rollbarLogFunction = rollbar[payload.logLevel] || rollbar.info;
          rollbarLogFunction(`Experience Payments UI: ${payload.message}`, payload.params);
          break;
        }
      }
    },
    [setIFrameHeight, setShowExpiredErrorModal, onSuccess],
  );

  const handleCloseModal = () => {
    setShowExpiredErrorModal(false);
  };

  const handleReloadForm = () => {
    setIframeKey(iframeKey + 1);
  };

  useEffect(() => {
    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  return (
    <div data-testid="experience-payments-ui-container">
      <iframe
        key={iframeKey}
        ref={iFrameRef}
        title="payment-form"
        src={`${experiencePaymentsURL}${initializeFormProps.path}`}
        onLoad={initializeHtmlForm}
        data-testid="payment-form-iframe"
        height={`${iFrameHeight + 20}px`}
        width="100%"
        frameBorder="0"
        css={css`
          margin: 32px -20px 0;
          max-width: 700px;
        `}
      />
      {showExpiredErrorModal && (
        <SessionExpiredModal isOpen={showExpiredErrorModal} onClose={handleCloseModal} handleCTA={handleReloadForm} />
      )}
    </div>
  );
};

ExperiencePaymentsUI.propTypes = {
  initializeFormProps: PropTypes.any.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ExperiencePaymentsUI;
