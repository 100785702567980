import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BILLING_CONTACT_PATH, pathToRoute } from '../../constants/routes';
import formatCurrency from '../../utils/format-currency';
import { DateTime } from 'luxon';

export const PaymentConfirmationModal = ({ paymentAmount, paymentMethod, lastFour, email, confirmationNumber }) => {
  const { prospectId, contactId } = useParams();
  const history = useHistory();
  const nowStr = DateTime.local().toLocaleString();

  const closeModal = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault();
      return;
    }
    history.replace(pathToRoute(BILLING_CONTACT_PATH, { prospectId, contactId }));
  };

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      data-testid="payment-confirmation-modal"
    >
      <>
        <DialogTitle id="form-dialog-title">Payment Processed</DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">
            {formatCurrency(paymentAmount)} was paid on {nowStr} from the {paymentMethod} ending in {lastFour}
          </DialogContentText>
          <DialogContentText color="textPrimary">A receipt was sent to {email}</DialogContentText>
          <DialogContentText color="textPrimary">
            <i>Processing may take 24-48 hours.</i>
          </DialogContentText>
          {confirmationNumber && (
            <DialogContentText
              color="textPrimary"
              css={css`
                font-weight: bold;
              `}
            >
              Confirmation Number: {confirmationNumber}
            </DialogContentText>
          )}
        </DialogContent>
      </>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PaymentConfirmationModal.propTypes = {
  paymentAmount: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  lastFour: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  confirmationNumber: PropTypes.string,
};
